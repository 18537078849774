import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)

export default class VideoMarkers extends React.Component {

  static propTypes = {
    items: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired
  }

  /**
   * Orders the markers by timestamp
   *
   * @param {Array} items
   * @returns {Array}
   */
  getOrderedMarkers(items) {
    return [...items].sort((a, b) => a.timestamp - b.timestamp)
  }

  render() {
    const { items, onClick } = this.props

    return <div className="video-markers-container">
      <h1>Chapters</h1>
      <div className="video-markers">
        {this.getOrderedMarkers(items).map((item) => {
          return <button key={item.timestamp} onClick={() => { onClick(item.timestamp) }}>
            {item.title} ({ moment.duration(item.timestamp, 'seconds').format('mm:ss', { trim: false }) })
          </button>
        })}
      </div>
    </div>
  }

}
