import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { UI } from '../config'

export default class HotSpot extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    active: PropTypes.bool,
  }

  getPercent(a, b) {
    return (a / b * 100) + '%'
  }

  render() {
    const { title, x, y, onClick, active } = this.props

    const styles = {
      top: this.getPercent(y, UI.EXPLORE_IMAGE_HEIGHT),
      left: this.getPercent(x, UI.EXPLORE_IMAGE_WIDTH)
    }

    const itemClass = classNames({
      'hot-spot': true,
      'active': active,
    })

    return <button className={itemClass} title={title} style={styles} onClick={onClick}>&nbsp;</button>
  }

}
