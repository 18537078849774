import React from 'react'

export function close({stroke = 'white'}) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" style={{enableBackground:"new 0 0 24 24"}} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 3L21 21" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21 3L3 21" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
