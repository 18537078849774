import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { trackSearchResultsTopicClick } from 'tw-oi-core/services/analytics'
import Loader from '../../components/Loader'
import TopicLayout from '../../components/TopicLayout'
import Media from '../../components/Media'
import TopicListItem from '../../components/TopicListItem'
import { generatePublicationLink } from '../../utils/publication'

import '../PdfPublicationMatches/PdfMatches.scss'

const PublicationGroup = ({
  baseRoute,
  isFetching,
  history,
  searchTopics,
  match,
  contentIndex,
}) => {
  const { params: { publicationId } } = match

  const topicGroupItems = useMemo(() => {
    const topicsList = (searchTopics && searchTopics.toJS()) || []

    return topicsList.filter(({ publication }) => {
      return publication.publicationId === publicationId
    }, topicsList)
  }, [searchTopics])

  return (
    <TopicLayout
      backRoute={() => history.goBack()}
      backTitle={contentIndex[publicationId].title}
    >
      <Media type="desktop">
        <ul className="topics-breadcrumb">
          <li>Search</li>
        </ul>
      </Media>

      {isFetching
        ? <Loader className="inverse" />
        : (

          <div className="topics">
            <ul className="TopicsList">
              {topicGroupItems.map((topic) => {
                const isFolder = topic.type === 'folder'
                const resourceKey = topic.resourceKey

                return (
                  <TopicListItem
                    key={topic.id}
                    to={`${baseRoute}/search${generatePublicationLink({ isFolder, resourceKey })}`}
                    {...{ topic }}
                    onClick={(topic) => trackSearchResultsTopicClick(topic.title)}
                  />
                )
              })}
            </ul>
          </div>
        )}
    </TopicLayout>
  )
}

PublicationGroup.propTypes = {
  baseRoute: PropTypes.string,
  isFetching: PropTypes.bool,
  searchQuery: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }),
  searchTopics: PropTypes.shape({
    toJS: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      publicationId: PropTypes.string,
    })
  }),
  contentIndex: PropTypes.shape({
    title: PropTypes.string
  }),
}

const mapStateToProps = (state) => ({
  searchTopics: state.contents.searchTopics,
  contentIndex: state.contents.contentIndex,
})

export default connect(mapStateToProps)(PublicationGroup)
