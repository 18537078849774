import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ScreenHead from './ScreenHead'
import NavBar from './NavBar'

class GuideError extends Component {

  static propTypes = {
    baseRoute: PropTypes.string.isRequired,
    className: PropTypes.string,
    children: PropTypes.any,
    title: PropTypes.string,
    backTitle: PropTypes.string,
    back: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  }

  static defaultProps = {
    className: "Guide",
    title: "Quick Guides",
    backTitle: null,
    back: null
  }

  render() {
    const { baseRoute, className, title, backTitle, back } = this.props

    return (
      <div className={className}>
        <div className="Topics">
          <ScreenHead title={title} backTitle={backTitle} back={back}/>
          <div className="screen-content">
            {this.props.children}
          </div>
        </div>
        <NavBar baseRoute={baseRoute} />
      </div>
    )
  }
}


export default GuideError
