/* global process */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'

import '../styles/App.scss'
import Main from './Main'
import Favicon from 'react-favicon'

class ThemeContainer extends Component {
  static propTypes = {
    currentBrand:PropTypes.string,
  }

  render() {
    const brand = this.props.currentBrand.toLowerCase()

    return <React.StrictMode>
      <div className={classNames("container", brand ? brand : "generic")}>
        <Favicon url={`${process.env.PUBLIC_URL}/images/favicon${brand ? "_" + brand : ""}.ico`} />
        <Main />
      </div>
    </React.StrictMode>
  }
}

function mapStateToProps({vehicle:{currentBrand}}) {
  return {currentBrand}
}

export default connect(mapStateToProps)(ThemeContainer)
