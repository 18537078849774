import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import { ROUTE } from '../config'
import { BRAND_NISSAN, BRAND_INFINITI } from "../config"

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as VehicleActions from 'tw-oi-core/actions/VehicleActions'
import ScreenHead from '../components/ScreenHead'


class Brands extends Component {

  static propTypes = {
    vehicle: PropTypes.shape({
      currentBrand: PropTypes.string,
    }).isRequired,
    VehicleActions: PropTypes.shape({
      setVehicleBrand: PropTypes.func.isRequired,
    }).isRequired
  }

  /**
   * Sets vehicle brand
   *
   * @param {String} brand to select
   * @returns {void}
   */
  onBtnClick(brand) {
    this.props.VehicleActions.setVehicleBrand(brand)
  }

  render() {
    return (
      <div className="BrandsSelectorScreen ">
        <ScreenHead back={ROUTE.INDEX}/>
        <div className="development-content">
          brands selection is available for development purposes only
        </div>
        <div className="brands-content">
          <Link onClick={this.onBtnClick.bind(this, "")} className="PrimaryButton brand-generic" to="/">[no brand]</Link>
          <Link onClick={this.onBtnClick.bind(this, BRAND_NISSAN)} className="PrimaryButton brand-nissan" to="/">Nissan</Link>
          <Link onClick={this.onBtnClick.bind(this, BRAND_INFINITI)} className="PrimaryButton brand-infiniti" to="/">Infiniti</Link>
        </div>
      </div>
    )
  }

}

function mapStateToProps({vehicle}) {
  return { vehicle }
}

function mapDispatchToProps(dispatch) {
  return {
    VehicleActions: bindActionCreators(VehicleActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Brands)
