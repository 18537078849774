import React from 'react'
import ImageMap from '../components/ImageMap'
import HotSpot from '../components/HotSpot'
import ImageMapSelector from '../components/ImageMapSelector'
import PropTypes from 'prop-types'
import { getDefaultHotSpot, isHotSpotDrillDown } from 'tw-oi-core/utils/contents'
import _ from 'lodash'

export default class VisualSearch extends React.Component {

  static propTypes = {
    imageMaps: PropTypes.array.isRequired,
    activeImageMap: PropTypes.string.isRequired,
    activeHotSpotIndex: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    isDesktop: PropTypes.bool
  }

  static defaultProps = {
    isDesktop: false
  }

  /**
   * Navigates to the specified hot spot
   *
   * In case of image map change - we select the best suitable hot spot
   *
   * @param {String} imageMap
   * @param {Number} index
   */
  toHotSpot(imageMap, index) {
    if (_.isUndefined(index)) {
      const currentItem = this.getActiveImageMap(this.props.imageMaps, imageMap)
      index = getDefaultHotSpot(currentItem.hotspots)
    }
    this.props.onChange(imageMap, index)
  }

  getRootElements(imageMaps) {
    const allKeys = imageMaps.map(x => x.resourceKey)
    const hotspots = [].concat(...imageMaps.map(x => x.hotspots)) // flatten multi-dimensional array of hotspots

    const usedKeys = hotspots // select image map resource keys used in targets
      .filter(x => allKeys.includes(x.targets[0])) // we care only about 0 element in case of link to image map
      .map(x => x.targets[0])

    return imageMaps // filter image maps that are not in targets
      .filter(x => !usedKeys.includes(x.resourceKey))
      .map(x => x.title)
  }

  getActiveImageMap(imageMaps, activeImageMap) {
    return imageMaps.find((item) => {
      return item.title === activeImageMap
    })
  }

  render() {
    const { imageMaps, activeImageMap, activeHotSpotIndex, isDesktop } = this.props

    // prepare data for ImageMap
    const currentItem = this.getActiveImageMap(imageMaps, activeImageMap)

    // get resourceKey => Type imageMap mapping (used for drill-down jumps)
    const keyToType = imageMaps.reduce((acc, cur) => {
      acc[cur.resourceKey] = cur
      return acc
    }, {})

    const hotSpots = currentItem.hotspots.map((item, index) => {
      let goTo // detect drill down hot-spots and go to appropriate
      if (isHotSpotDrillDown(item.targets, imageMaps)) {
        const imageMapKey = item.targets[0]
        const drillDownImageMap = keyToType[imageMapKey]
        if (!drillDownImageMap) {
          throw new Error('Unknown drill-down image map resourceKey: '  + imageMapKey)
        }
        goTo = { // jump to drill-down image map
          toImageMap: drillDownImageMap.title,
          toIndex: getDefaultHotSpot(drillDownImageMap.hotspots)
        }
      } else { // just navigate inside the current image map
        goTo = {
          toImageMap: activeImageMap,
          toIndex: index
        }
      }

      return {...item, ...goTo, isActive: activeHotSpotIndex === index}
    })

    // prepare data for ImageMapSelector filterTopicsByField
    const imageMapSelectorItems = this.getRootElements(imageMaps)

    const activeHotSpot = hotSpots.find((spot) => {
      return spot.isActive === true
    })

    return (
      <div className="visual-search">
        <div className="image-map-container">
          <ImageMap src={currentItem.url} activeX={activeHotSpot.x} activeY={activeHotSpot.y} isDesktop={isDesktop}>
            {hotSpots.map((item, index) =>
              <HotSpot key={index} x={item.x} y={item.y} title={item.title} active={item.isActive}
                onClick={this.toHotSpot.bind(this, item.toImageMap, item.toIndex)} />
            )}
          </ImageMap>
          <ImageMapSelector items={imageMapSelectorItems} active={activeImageMap} onChange={this.toHotSpot.bind(this)} />
        </div>
      </div>
    )
  }
}
