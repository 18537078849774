/**
 * Shim to make IE support classList for SVG elements
 * @see https://github.com/Pomax/react-onclickoutside#ie-does-not-support-classlist-for-svg-elements
 */

if (!("classList" in SVGElement.prototype)) {
  Object.defineProperty(SVGElement.prototype, "classList", {
    get() {
      return {
        contains: className => {
          return this.className.baseVal.split(" ").indexOf(className) !== -1
        }
      }
    }
  })
}
