import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Page } from 'react-pdf'

class PageRenderer extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    scale: PropTypes.number,
    cachedPageDimensions: PropTypes.shape({}),
  }

  shouldComponentUpdate(prevProps) {
    return this.props.index !== prevProps.index ||
      this.props.scale !== prevProps.scale ||
      this.props.cachedPageDimensions !== prevProps.cachedPageDimensions ||
      this.props.style !== prevProps.style
  }

  handleRenderSuccess = (page) => {
    this.props.data.onPageRenderSuccess(page.pageIndex)
  }

  render() {
    const { index, data, style } = this.props
    const {
      cachedPageDimensions,
      onPageLoadSuccess,
      scale,
      loader,
      onGetTextSuccess
    } = data
    const pageNumber = index + 1

    return (
      <div
        {...{ style }}
        className="PdfPreview__Page"
      >
        <div
          className="PdfPreview__Page-inner-container"
          style={{
            width: scale * cachedPageDimensions.get(index).width
          }}
        >
          <Page
            {...{ scale, pageNumber }}
            width={cachedPageDimensions.get(index).width}
            onLoadSuccess={onPageLoadSuccess}
            loading={loader}
            onRenderSuccess={this.handleRenderSuccess}
            onGetTextSuccess={onGetTextSuccess}
          />
        </div>
      </div>
    )
  }

}

export default PageRenderer
