import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'

import { UI, ROUTE } from '../config'

import '../styles/VehicleYearSelector.scss'

export default class VehicleYearSelector extends Component {

  static propTypes = {
    currentYear: PropTypes.string.isRequired,
    years: PropTypes.array.isRequired,
    onSelected: PropTypes.func.isRequired,
    slider: PropTypes.bool,
    label: PropTypes.string
  }

  onYearBtnClick(e) {
    const clickedYear = e.target.dataset.year
    if (this.props.currentYear !== clickedYear) {
      this.props.onSelected(clickedYear)
    }
  }

  componentDidMount() {
    if (this.props.slider)
      setTimeout(() => {
        if (this.props.currentYear && this.slider) {
          let index = this.props.years.indexOf(this.props.currentYear)

          //slide to current year if there are more than 2 others before it
          index = index > 2 ? index - 2 : 0

          this.slider.slickGoTo(index)
        }
      })
  }

  componentDidUpdate(prevProps) {
    if (this.props.slider && this.props.currentYear !== prevProps.currentYear) {
      setTimeout(() => {
        if (this.props.currentYear && this.slider) {
          let index = this.props.years.indexOf(this.props.currentYear)

          //slide to current year if there are more than 2 others before it
          index = index > 2 ? index - 2 : 0

          this.slider.slickGoTo(index)
        }
      })
    }
  }

  render() {
    const { currentYear, years, label } = this.props

    const buttons = []
    for (let i = 0; i < years.length; i++) {

      buttons.push(
        <div className="wrapper" key={i}>
          <Link
            to={`${ROUTE.VEHICLES}/${years[i]}/`}
            {...years[i] === currentYear ? {className: 'current'} : {}}
            data-year={years[i]}
            onClick={this.onYearBtnClick.bind(this)}>
            {years[i]}
          </Link>
        </div>
      )
    }

    if (this.props.slider) {
      return (
        <div className="VehicleYearSelector">
          <div className="VehicleYearSelector__label">Select Year</div>
          <Slider ref={(c) => { this.slider = c }} {...{
            className: 'center',
            dots: false,
            arrows: false,
            infinite: false,
            swipeToSlide: true,
            variableWidth: true,
            speed: UI.ANIMATION_DELAY,
            /*afterChange: (index) => {
             get current slide index
             },*/
          }}>
            {buttons}
          </Slider>
        </div>
      )
    } else {
      return (
        <div className="VehicleYearSelector">
          <div className="VehicleYearSelector__label">Select Year</div>
          {!!label && <label className="VehicleYearSelector__label">{label}</label>}
          <div style={{display:'flex', flexFlow:'row wrap'}}>
            {buttons}
          </div>
        </div>
      )
    }

  }
}
