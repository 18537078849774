import React from 'react'
import propTypes from 'prop-types'

class BodyClass extends React.Component {
  static propTypes = {
    className: propTypes.string,
    condition: propTypes.bool,
    children: propTypes.any
  }

  componentDidMount() {
    document.body.classList.toggle(this.props.className, this.props.condition)
  }
  componentDidUpdate() {
    document.body.classList.toggle(this.props.className, this.props.condition)
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.className)
  }
  render() {
    return this.props.children || null
  }
}

export default BodyClass
