import React, { useState } from 'react'
import PropTypes from 'prop-types'
import OutsideClickHandler from 'react-outside-click-handler'

import { SORT_ORDER } from './utils'

import './SortSelector.scss'

const SORT_ORDER_LABELS = {
  [SORT_ORDER.RANK]: 'Search Rank',
  [SORT_ORDER.PAGE]: 'Page Number',
}

const SortSelector = ({ sortOrder, onSortOrderSelect }) => {
  const [isOpen, setIsOpen] = useState(false)
  const onItemSelect = (ev, order) => {
    ev.stopPropagation()
    onSortOrderSelect(order)
    setIsOpen(false)
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => setIsOpen(false)}
    >
      <div className="SortSelector">
        <div className="SortSelector__label">
          Sort by:&nbsp;
          <button
            onClick={() => setIsOpen(true)}
            className="SortSelector__btn"
          >
            {SORT_ORDER_LABELS[sortOrder]}
          </button>
        </div>

        {isOpen && <div className="Menu">
          <div
            className="menu-item"
            onClick={ev => onItemSelect(ev, SORT_ORDER.RANK)}
          >
            {SORT_ORDER_LABELS[SORT_ORDER.RANK]}
          </div>
          <div
            className="menu-item"
            onClick={ev => onItemSelect(ev, SORT_ORDER.PAGE)}
          >
            {SORT_ORDER_LABELS[SORT_ORDER.PAGE]}
          </div>
        </div>}

      </div>
    </OutsideClickHandler>
  )
}

SortSelector.propTypes = {
  sortOrder: PropTypes.string,
  onSortOrderSelect: PropTypes.func
}

export default SortSelector
