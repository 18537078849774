import { generatePath } from 'react-router'
import qs from 'qs'

import { ROUTE } from '../config'

export const generatePublicationLink = ({
  isPdfPublication,
  searchQuery,
  resourceKey,
  isFolder,
  matches,
}) => {
  if (isPdfPublication) {
    return (searchQuery && matches.length > 0)
      ? `${generatePath(ROUTE.PDF_PUBLICATION_MATCHES, { resourceKey })}?${qs.stringify({ q: searchQuery })}`
      : `${generatePath(ROUTE.PDF_PUBLICATION, { resourceKey })}`
  }
  return `${isFolder ? ROUTE.FOLDER : ROUTE.TOPIC}/${resourceKey}`
}

export const isWarningLightsPublication = item => item.publicationType === 'WL' || item.publicationSubType === 'Icon Glossary'
