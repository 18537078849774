import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { trimSearchQuery } from 'tw-oi-core/utils/ui'

import { UI } from '../config'

import Icon from './Icon'

import '../styles/SearchInput.scss'

export default class SearchInput extends PureComponent {

  static propTypes = {
    onChange: PropTypes.func,
    onToggle: PropTypes.func,
    searchQuery: PropTypes.string,
    autoFocus: PropTypes.bool,
    onGoBack: PropTypes.func
  }

  static defaultProps = {
    onChange: () => null,
    onToggle: () => null,
    searchQuery: '',
    autoFocus: true
  }

  input = null

  constructor(props) {
    super(props)

    this.state = {
      query: this.props.searchQuery
    }

    this.handleChangeDelayed = _.debounce(this.handleChangeDelayed, UI.SEARCH_BOX_DELAY)
  }

  clearSearchQuery = () => {
    if (this.state.query) {
      this.setState({ query: "" })
      this.props.onChange("")
    } else {
      this.props.onGoBack()
    }
  }

  handleChange = (event) => {
    const query = trimSearchQuery(event.target.value)
    this.setState({ query })
    this.handleChangeDelayed()
  }

  handleFocus = () => {
    if (this.props.onToggle) this.props.onToggle(true)
  }

  handleBlur = () => {
    if (this.props.onToggle) setTimeout(() => this.props.onToggle(false), UI.FRAME_DELAY)
  }

  /**
   * Initiate change event with delay
   */
  handleChangeDelayed = () => {
    // Do not initiate search event when user typed in too few characters
    const len = this.state.query.length
    if (len > 0 && len < UI.SEARCH_MIN_CHARACTERS) {
      return
    }

    this.props.onChange(this.state.query)
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      setTimeout(() => {
        if (this.input) {
          // set carret position to the end of searchQuery
          if (this.state.query) {
            let caretPos = this.state.query.length

            if (typeof this.input.createTextRange !== 'undefined') {
              let range = this.input.createTextRange()
              range.move('character', caretPos)
              range.select()
            } else {
              if (typeof this.input.selectionStart !== 'undefined') {
                this.input.focus()
                this.input.setSelectionRange(caretPos, caretPos)
              } else {
                this.input.focus()
              }
            }
          } else
            this.input.focus()

        }
      }, UI.ANIMATION_DELAY)
    }
  }

  render() {
    return (
      <div className='SearchInput'>
        <div className="SearchInput__inner-container">

          <div className='SearchInput__search-icon'>
            <Icon type='search' />
          </div>
          <input 
            type="text"
            ref={input => this.input = input}
            value={this.state.query}
            placeholder="Search manuals & guides"
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          />
          {<button className="cancel-search" onClick={this.clearSearchQuery}><Icon type="cancel" /></button>}
        </div>
      </div>
    )
  }
}
