import React from 'react'

import { CloseIcon } from '../../icons'

import './CloseBtn.scss'

const CloseBtn = props => (
  <button className='CloseBtn' {...props}>
    <CloseIcon />
  </button>
)

export default CloseBtn
