import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as UserActions from 'tw-oi-core/actions/UserActions'

import { ScrollSink } from '../components/MotionScroll'

const ScreenContainer = ({ scrollTop = 0, scrollUrl, currentUrl, children, UserActions }) => {
  const scrollContainer = useRef()
  let scrollTimeout = null

  useEffect(() => {
    if (scrollContainer && scrollContainer.current) {
      scrollContainer.current.addEventListener('scroll', handleScroll)
    }

    // update scroll state if url changed
    if (scrollUrl !== currentUrl) {
      UserActions.setScrollState(null, null)
    }

    return () => {
      // if still scrolling -> set immediately
      if (scrollTimeout) {
        window.clearTimeout(scrollTimeout)
        if (scrollContainer.current) {
          UserActions.setScrollState(scrollContainer.current.scrollTop, currentUrl)
        }
      }

      if (scrollContainer && scrollContainer.current) {
        scrollContainer.current.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const handleScroll = () => {
    //on init always firing scrollTop with 0, so ignore
    if (!scrollTimeout && !scrollContainer.current) return
    if (!scrollContainer.current.scrollTop) return

    if (scrollTimeout) {
      window.clearTimeout(scrollTimeout)
    }

    scrollTimeout = window.setTimeout(() => {
      if (scrollContainer.current) {
        UserActions.setScrollState(scrollContainer.current.scrollTop, currentUrl)
      }
    }, 300)
  }

  return (
    <ScrollSink scrollTop={scrollTop}>
      <div
        className="screen-container"
        ref={scrollContainer}
      >
        {children}
      </div>
    </ScrollSink>
  )
}

ScreenContainer.propTypes = {
  scrollTop: PropTypes.number,
  scrollUrl: PropTypes.string,
  currentUrl: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  UserActions: PropTypes.shape({
    setScrollState: PropTypes.func.isRequired
  }),
}

function mapStateToProps({ user }) {
  const { scrollTop, scrollUrl } = user

  return { scrollTop, scrollUrl }
}

function mapDispatchToProps(dispatch) {
  return {
    UserActions: bindActionCreators(UserActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenContainer)
