import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import {ROUTE} from '../config'
import Media from '../components/Media'

import '../styles/NavBar.scss'

export default class NavBar extends Component {
  static propTypes = {
    baseRoute: PropTypes.string.isRequired,
    explore: PropTypes.bool,
    topPicks: PropTypes.bool,
    favorites: PropTypes.bool
  }

  static defaultProps = {
    explore: false,
    topPicks: false,
    favorites: false
  }

  render() {
    const { explore, topPicks, baseRoute } = this.props

    return <Media type="mobile">
      <div className="NavBar">
        {explore && <NavLink className="nav-link" to={`${baseRoute}${ROUTE.EXPLORE}`}>Explore</NavLink>}
        <NavLink className="nav-link" to={`${baseRoute}${ROUTE.BROWSE}`}>Manuals</NavLink>
        {topPicks && <NavLink className="nav-link" to={`${baseRoute}${ROUTE.TOPPICKS}`}>Top Picks</NavLink>}
      </div>
    </Media>
  }
}
