import React from 'react'
import { Link } from 'react-router-dom'
import { identity } from 'lodash/fp'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Media from './Media'
import Icon from './Icon'
import Loader from './Loader'


const TopicListItem = ({
  to,
  onClick = identity,
  topic,
  matches,
  isFetching,
}) => {
  const subtitle = (matches && matches.length)
    ? `${matches.length} Matches`
    : ''

  const isFolder = topic.type === 'folder'
  const hasVideo = !!topic.video
  const hasImage = false //TODO: check for images in topic

  return (
    <li className="TopicsList__item">
      <Link className="item-inner"
        to={to}
        onClick={() => onClick(topic)}>
        <div className="item-title">
          <div className="TopicsList__match">
            <Media type="mobile">
              {isFetching && <Loader className={classNames('inverse', 'Loader--match')} />}
              {!isFetching && !!subtitle && <div className="TopicsList__match-subtitle">
                {subtitle}
              </div>}
            </Media>

            <span>{topic.title}</span>

            <Media type="desktop" className="TopicsList__match-aside">
              {isFetching && <Loader className={classNames('inverse', 'Loader--match')} />}
              {!isFetching && !!subtitle && <div className="TopicsList__match-subtitle TopicsList__match-subtitle--aside">
                {subtitle}
              </div>}
            </Media>
          </div>
        </div>
        <div className="item-iconbar">
          {hasVideo && <Icon type="list-film" />}
          {hasImage && <Icon type="list-image" />}
          {!isFolder && !hasVideo && !hasImage && <Icon type="list-topic" />}

          {/* TODO */}
          {/* {!isDesktop && isFolder && <Icon type="arrow-left" />} */}
          {/* {isDesktop && isFolder && <Icon type="folder" />} */}
        </div>
      </Link>
    </li>
  )
}

TopicListItem.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  topic: PropTypes.object,
  matches: PropTypes.arrayOf(PropTypes.shape({})),
  isFetching: PropTypes.bool,
}

export default TopicListItem
