import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import ScreenHead from '../ScreenHead'

const TopicLayout = ({
  className,
  currentYear,
  currentBrand,
  currentModel,
  userMedia,
  backRoute,
  backTitle,
  title,
  subTitle,
  children,
  fullSpace,
  centerContent
}) => (
  <div className={classNames("Topics", "one", className)}>
    <ScreenHead
      title={`${currentYear} ${currentBrand} ${currentModel}`}
      back={backRoute}
      backTitle={backTitle}
      landscapeOverlay={false}
    />

    <div className={classNames('screen-content', fullSpace && 'screen-content--full-space')}>
      <div className="screen-container">
        {subTitle}
        {centerContent
          ? <div className="Topics__content-center">
            {userMedia.isDesktop && title && <h1 className="topic-title">{title}</h1>}
            {children}
          </div>
          : <>
            {userMedia.isDesktop && title && <h1 className="topic-title">{title}</h1>}
            {children}
          </>}
      </div>
    </div>
  </div>
)

TopicLayout.propTypes = {
  className: PropTypes.string,
  currentYear: PropTypes.string,
  currentBrand: PropTypes.string,
  currentModel: PropTypes.string,
  userMedia: PropTypes.object,
  backRoute: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  backTitle: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  fullSpace: PropTypes.bool,
  centerContent: PropTypes.bool,
}

const mapStateToProps = ({ vehicle, user }) => {
  const { currentYear, currentModel, currentBrand } = vehicle
  const { media: userMedia } = user

  return { currentYear, currentModel, currentBrand, userMedia }
}

export default connect(mapStateToProps)(TopicLayout)
