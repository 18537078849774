import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { MESSAGE } from '../config'
import classNames from 'classnames'
import Icon from './Icon'
import { Link } from 'react-router-dom'

import '../styles/ErrorMessage.scss'

export default class ErrorMessage extends Component {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
    retryAction: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    retryTitle: PropTypes.string,
    icon: PropTypes.oneOf(["no-connection"])
  }
  static defaultProps = {
    title: MESSAGE.ERROR_GLOBAL,
    message: MESSAGE.ERROR_RETRY,
    retryAction: null,
    retryTitle: "Retry",
    icon:"no-connection"
  }
  render() {
    const { icon, title, message, retryAction, retryTitle, className } = this.props
    return (
      <div className={classNames("ErrorMessage", className)}>
        <div className="inner">
          <Icon type={icon}/>
          <h1>{title}</h1>
          <span className="message">{message}</span>
          {typeof(retryAction) === 'function' ?
            <button onClick={retryAction} className="PrimaryButton">{retryTitle}</button> :
            <Link to={retryAction} className="PrimaryButton">{retryTitle}</Link>
          }
        </div>
      </div>
    )
  }
}
