import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import classNames from 'classnames'
import { generatePath } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { pathOr, groupBy, toPairs, prop, isEmpty } from 'lodash/fp'

import * as SearchPagesActions from 'tw-oi-core/actions/SearchPagesActions'

import Loader from '../components/Loader'
import { ROUTE } from '../config'
import { generatePublicationLink } from '../utils/publication'
import '../styles/TopicsList.scss'

import TopicListItem from './TopicListItem'

const TopicsListSearch = ({
  topics,
  searchQuery,
  searchPublications,
  baseRoute,
  onClick,
  children,
  emptyText,
  className,
  getSearchPages,
  searchPagesByPublication,
  contentIndex
}) => {
  const pdfPublications = useMemo(() => {
    return searchPublications.filter(publication => !!publication.get('bodyPdf')).toJS()
  }, [searchPublications])

  useEffect(() => {
    if (searchQuery) {
      pdfPublications
        .forEach(publication => {
          getSearchPages({
            publicationId: publication.id,
            searchQuery,
          })
        })
    }
  }, [searchQuery, pdfPublications])

  const groupedTopics = useMemo(() => {
    const topicsList = (topics && topics.toJS()) || []
    return groupBy(({ publication }) => publication.publicationId, topicsList)
  }, [topics])

  if (isEmpty(groupedTopics) && !prop('length', pdfPublications)) {
    return <Loader type="status" className="inverse">{emptyText}</Loader>
  }

  return (
    <ul className={classNames('TopicsList', className)}>
      {toPairs(groupedTopics)
        .map(([publicationId, topics]) => {
          if (topics.length === 1) {
            const topic = topics[0]
            const isFolder = topic.type === 'folder'
            const resourceKey = topic.resourceKey

            return (
              <TopicListItem
                key={topic.publication.publicationId}
                to={`${baseRoute}${generatePublicationLink({ isFolder, resourceKey })}`}
                {...{ topic, onClick }}
              />
            )
          }

          return (
            <TopicListItem
              key={publicationId}
              to={`${baseRoute}${generatePath(ROUTE.PUBLICATION_GROUP, { publicationId })}`}
              onClick={onClick}
              topic={contentIndex[publicationId]}
              matches={topics}
            />
          )
        })}


      {pdfPublications.map((topic) => {
        const publicationState = searchPagesByPublication[topic.id]
        const matches = pathOr([], ['data', 'items'], publicationState)
        const isFetching = prop('isFetching', publicationState)
        const resourceKey = topic.resourceKey

        return (
          <TopicListItem
            key={topic.id}
            to={`${baseRoute}${generatePublicationLink({ isPdfPublication: true, searchQuery, resourceKey, matches })}`}
            {...{ topic, onClick, matches, isFetching }}
          />
        )
      })}

      {children}
    </ul>
  )
}

TopicsListSearch.propTypes = {
  topics: PropTypes.oneOfType([ImmutablePropTypes.list, ImmutablePropTypes.map]),
  searchPublications: ImmutablePropTypes.list,
  searchQuery: PropTypes.string,
  emptyText: PropTypes.string,
  baseRoute: PropTypes.string.isRequired,
  className: PropTypes.string,
  isDesktop: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  getSearchPages: PropTypes.func,
  searchPagesByPublication: PropTypes.object,
  contentIndex: PropTypes.object,
}

TopicsListSearch.defaultProps = {
  searchQuery: "",
  emptyText: "No topics found",
  isDesktop: false,
  searchPagesByPublication: {},
}

const mapStateToProps = (state) => ({
  contentIndex: state.contents.contentIndex,
  topics: state.contents.searchTopics,
  searchPagesByPublication: state.contents.searchPagesByPublication,
  searchPublications: state.contents.searchPublications,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(SearchPagesActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TopicsListSearch)
