import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { isNaN, toNumber, find, path, pathOr } from 'lodash/fp'
import ImmutablePropTypes from 'react-immutable-proptypes'

import TopicLayout from '../../components/TopicLayout'
import PdfViewer from '../../components/PdfViewer'

import { parseQuery } from './utils'
import withSearchResults from './withSearchResults'

class PdfPublication extends PureComponent {
  static getDerivedStateFromProps = ({ history, searchPagesByPublication, publication }) => {
    const { q } = parseQuery(path(['location', 'search'], history))
    const pages = pathOr([], [publication.get('id'), 'data', 'items'], searchPagesByPublication)
    return {
      pages,
      hasPagesPreview: !!pages.length && !!q
    }
  }

  constructor(props) {
    super(props)

    const {
      history: { location: { search } },
    } = this.props

    const { page } = parseQuery(search)
    const pageIndex = toNumber(page)

    this.state = {
      selectedSearchResult: null,
      selectedPageIndex: isNaN(pageIndex) ? -1 : pageIndex,
    }
  }

  componentDidMount() {
    this.setState({
      selectedSearchResult: find({ index: this.state.selectedPageIndex }, this.props.searchResults)
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchResults !== prevProps.searchResults) {
      this.setState({
        selectedSearchResult: find({ index: this.state.selectedPageIndex }, this.props.searchResults)
      })
    }
  }

  handleSearchResultSelect = (searchResult) => {
    this.setState({
      selectedPageIndex: searchResult.index,
      selectedSearchResult: searchResult,
    })
  }

  render() {
    const { selectedPageIndex, selectedSearchResult, hasPagesPreview, pages } = this.state
    const { publication, history } = this.props

    return (
      <TopicLayout
        fullSpace
        backRoute={() => history.goBack()}
        backTitle={publication.get('title')}
      >
        <PdfViewer
          file={publication.getIn(['bodyPdf', 'url'])}
          {...{ hasPagesPreview, pages }}
          selectedPageIndex={selectedPageIndex - 1}
          onSearchResultSelect={this.handleSearchResultSelect}
          selectedSearchResult={selectedSearchResult}
        />
      </TopicLayout>
    )
  }
}

PdfPublication.propTypes = {
  match: PropTypes.object,
  publications: ImmutablePropTypes.list,
  history: PropTypes.object.isRequired,
  searchPagesByPublication: PropTypes.object,
  getSearchPages: PropTypes.func,
  searchQuery: PropTypes.string,
  publication: PropTypes.shape({
    getIn: PropTypes.func,
    get: PropTypes.func,
  }),
  searchResults: PropTypes.arrayOf(PropTypes.shape({})),
}

export default withSearchResults(PdfPublication)
