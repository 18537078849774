/*eslint no-restricted-globals: 0*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { filterTopicsByTopPicks, sortItemsByTitle } from 'tw-oi-core/utils/data'
import { trackTopPicksTopicClick } from 'tw-oi-core/services/analytics'

import { MESSAGE } from '../config'

import { connect } from 'react-redux'
import ScreenHead, { SCREEN_HEAD_VARIANT } from '../components/ScreenHead'
import ScreenContainer from '../components/ScreenContainer'
import TopicsList from '../components/TopicsList'

import '../styles/Topics.scss'

class TopPicks extends Component {

  static propTypes = {
    fetching: PropTypes.bool,
    topics: ImmutablePropTypes.list,
    favorites: PropTypes.array,
    baseRoute: PropTypes.string.isRequired,
    currentYear: PropTypes.string,
    currentModel: PropTypes.string,
    currentBrand: PropTypes.string,
    match: PropTypes.object.isRequired,
    navBarComponent: PropTypes.elementType,
  }

  render() {
    const { baseRoute, topics, match, navBarComponent } = this.props

    // TODO: move these to selector function
    const topTics = filterTopicsByTopPicks(topics)
    const sortedTopPics = sortItemsByTitle(topTics)

    return (
      <div className="Topics">
        <ScreenHead
          title="Manuals"
          withSearch
          baseRoute={baseRoute}
          variant={SCREEN_HEAD_VARIANT.SEARCH}
        />

        {navBarComponent}

        <div className="screen-content">
          <ScreenContainer currentUrl={match.url}>
            <div className="topics">
              <TopicsList
                topics={sortedTopPics}
                emptyText={MESSAGE.EMPTY_TOPICS}
                baseRoute={match.url}
                className="TopPicksList"
                onClick={(topic) => trackTopPicksTopicClick(topic.get('title'))}
              />
            </div>
          </ScreenContainer>
        </div>
      </div>
    )
  }
}

function mapStateToProps({ contents, vehicle }) {
  const { topics, fetching, currentAsset, fetchingAsset } = contents
  const { currentYear, currentModel, currentBrand } = vehicle

  return { topics, fetching, currentAsset, fetchingAsset, currentYear, currentModel, currentBrand }
}

export default connect(mapStateToProps)(TopPicks)
