/*eslint no-restricted-globals: 0*/
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { checkGlobals } from 'tw-oi-core/utils/ui'
import 'react-app-polyfill/ie11'
import 'babel-polyfill'
import './polyfills'

// TODO: come up with solution for serviceWorker issues
// import registerServiceWorker from './registerServiceWorker'

const startApp = () => {
  ReactDOM.render(<App />, document.getElementById('root'))

  // TODO: come up with solution for local (file://) app in cordova environment
  // TODO: come up with solution for QR-code redirects and absolute URLs like https://nissanquickguide.com/2016/maxima
  // registerServiceWorker()

  if (checkGlobals(['cordova','screen'])) {
    window.screen.orientation.lock('portrait')
  }
}

if (checkGlobals(['cordova'])) {
  document.addEventListener('deviceready', startApp, false)
} else {
  startApp()
}
