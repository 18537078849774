import { useState, useMemo } from 'react'
import { sortBy } from 'lodash/fp'

export const SORT_ORDER = {
  PAGE: 'index',
  RANK: 'searchIndex',
}

export function useSorting(initialSortOrder, items) {
  const [sortOrder, setSortOrder] = useState(initialSortOrder)
  const sortedItems = useMemo(() => sortBy([sortOrder], items), [items, sortOrder])

  return [sortedItems, sortOrder, setSortOrder]
}
