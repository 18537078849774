import React, { useLayoutEffect, useRef, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { trackWarningLightsIconClick } from 'tw-oi-core/services/analytics'

import ExpandImageModal from './ExpandImageModal/ExpandImageModal'
import { ROUTE } from '../config'

const isInternalLink = el => {
  return el.tagName.toLowerCase() === 'a' && el.classList.contains('xref') && el.dataset.scope === 'internal'
}

const TopicText = ({ content, match, baseRoute, history, foldersIndex, contentIndex }) => {
  const topicTextRef = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalImage, setModalImage] = useState(null)

  const cleanupEmptyLinks = useCallback(() => {
    const emptyLinks = Array.from(topicTextRef.current.querySelectorAll('.xref[href=""]'))
    emptyLinks.forEach((link) => {
      const span = document.createElement('span')
      span.innerHTML = link.innerHTML
      link.parentNode.replaceChild(span, link)
    })
  }, [])

  const markContainersWithExpandableImages = useCallback(() => {
    const images = Array.from(topicTextRef.current.querySelectorAll('.image.expand'))
    images.forEach(image => {
      const parent = image.parentNode
      parent.classList.add('with-expandable-img')

      const imgWrapper = document.createElement('div')
      imgWrapper.classList.add('expandable-img-wrapper')

      parent.replaceChild(imgWrapper, image)
      imgWrapper.appendChild(image)
    })
  }, [])

  useLayoutEffect(() => {
    if (topicTextRef && topicTextRef.current) {
      cleanupEmptyLinks()
      markContainersWithExpandableImages()
    }
  }, [cleanupEmptyLinks, markContainersWithExpandableImages])

  const handleClick = e => {
    if (isInternalLink(e.target) || isInternalLink(e.target.parentNode)) {
      e.preventDefault()

      const linkEl = isInternalLink(e.target) ? e.target : e.target.parentNode
      if (!linkEl.dataset.guid) {
        console.log('Topic GUID is missing')
        return
      }

      const topicGUID = linkEl.dataset.guid
      const guidPrefix = linkEl.dataset.guidPrefix || 'GUID'
      const itemResourceKey = `${guidPrefix}-${topicGUID}`
      const itemPath = foldersIndex.toJS()[itemResourceKey] ? ROUTE.FOLDER : ROUTE.TOPIC

      const topic = Object.values(contentIndex).find(content => content.resourceKey === itemResourceKey)
      trackWarningLightsIconClick(topic.title)

      history.push(`${baseRoute}/${match.params.referrer}${itemPath}/${itemResourceKey}`)
    }

    let eventTarget = e.target
    // set event target to image if clicked on expandable icon located in :after pseudo class
    if (eventTarget.classList.contains('expandable-img-wrapper')) {
      eventTarget = eventTarget.querySelector('img.expand')
    }

    if (eventTarget.classList.contains('expand')) {
      setIsModalOpen(true)
      setModalImage(eventTarget.src)
    }
  }

  return (
    <>
      <div
        ref={topicTextRef}
        onClick={handleClick}
        className='topic-content'
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <ExpandImageModal image={modalImage} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  )
}

TopicText.propTypes = {
  content: PropTypes.string.isRequired,
  history: PropTypes.object,
  baseRoute: PropTypes.string,
  match: PropTypes.object,
  foldersIndex: ImmutablePropTypes.map,
  contentIndex: ImmutablePropTypes.map,
}

function mapStateToProps({ contents }) {
  const { contentIndex } = contents

  return {
    contentIndex,
  }
}

export default connect(mapStateToProps)(withRouter(TopicText))
