import React from 'react'
import classNames from 'classnames'
import { prop } from 'lodash/fp'
import PropTypes from 'prop-types'

const SearchResultsList = ({
  items,
  selectedItem,
  onSelect,
  className,
}) => (
  <ul className={classNames('SearchResults__items', className)}>
    {items.map(item => (
      <li
        key={`${item.id}_${item.index}`}
        className={classNames('SearchResults__item', prop('index', selectedItem) === item.index && 'SearchResults__item--selected')}
        onClick={() => onSelect(item)}
      >
        <div className="SearchResults__item-page">{`Page: ${item.index}`}</div>
        {item.searchHighlights.bodyText && <div
          className="SearchResults__item-content"
          dangerouslySetInnerHTML={{ __html: item.searchHighlights.bodyText[0] }}
        />}
      </li>
    ))}
  </ul>
)

SearchResultsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  selectedItem: PropTypes.shape({}),
  onSelect: PropTypes.func,
  className: PropTypes.string,
}

export default SearchResultsList
