import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { connect } from 'react-redux'

import { close as CloseIcon } from '../../svg/close'
import './ExpandImageModal.scss'

Modal.setAppElement('body')

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 1,
  },
}

const ExpandImageModal = ({ isOpen, onClose, image, currentBrand, media }) => {
  return (
    <Modal
      className={`expand-image-modal ${currentBrand}`}
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      style={customStyles}
    >
      {image && (
        <div className='image-container-modal'>
          <img src={image} />
        </div>
      )}

      <button className='close-btn' onClick={() => onClose()}>
        <CloseIcon stroke={media.isDesktop ? '#fff' : '#000'} /> Close
      </button>
    </Modal>
  )
}

ExpandImageModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  image: PropTypes.string,
  currentBrand: PropTypes.string,
  media: PropTypes.object,
}

function mapStateToProps({ vehicle, user }) {
  const { currentBrand } = vehicle
  const { media } = user

  return { currentBrand, media }
}

export default connect(mapStateToProps)(ExpandImageModal)
