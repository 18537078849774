import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import CloseBtn from './CloseBtn'
import SearchResultsList from './SearchResultsList'
import { SORT_ORDER, useSorting } from './SortSelector/utils'
import SortSelector from './SortSelector'

const SearchResults = ({
  items,
  onSelect,
  onClose,
  selectedItem,
  isOpen,
}) => {
  const [sortedItems, sortOrder, setSortOrder] = useSorting(SORT_ORDER.RANK, items || [])

  return (
    <aside className={classNames('SearchResults', isOpen && 'SearchResults--open')}>
      <div className="SearchResults__header">
        <CloseBtn onClick={onClose} />

        <SortSelector
          sortOrder={sortOrder}
          onSortOrderSelect={setSortOrder}
        />
      </div>
      <SearchResultsList
        items={sortedItems}
        onSelect={onSelect}
        selectedItem={selectedItem}
      />
    </aside>
  )
}

SearchResults.propTypes = {
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object),
  selectedItem: PropTypes.shape({}),
  isOpen: PropTypes.bool,
}

export default SearchResults
