import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import { MESSAGE, ROUTE } from '../config'
import { Link } from 'react-router-dom'


class ErrorBoundary extends Component {
  state = {
    error: '',
    errorInfo: '',
    hasError: false,
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo })
    this.setState({ errorInfo })
  }

  render() {
    const { hasError } = this.state
    if (hasError) {
      return <div className="ErrorMessage inverse">
        <div className="inner">
          <h1>{MESSAGE.ERROR_GLOBAL}</h1>
          <span className="message">{MESSAGE.ERROR_NOT_FOUND_MESSAGE}</span>
          <Link onClick={() => this.setState({hasError: false})} to={ROUTE.INDEX} className="PrimaryButton">Visit Home Page</Link>
        </div>
      </div>
    }

    return this.props.children
  }
}

export default withRouter(ErrorBoundary)

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  history: PropTypes.object.isRequired
}
