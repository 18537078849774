const HIGHLIGHT_REGEX = /<em>(.|\n)*?<\/em>/ig

const extractHighlightText = highlightTag => highlightTag
  .replace('<em>', '')
  .replace('</em>', '')

export const extractHightlightWords = (searchHighlights) => {
  const { bodyText } = searchHighlights
  if (!bodyText) return []

  const words = bodyText.reduce((acc, text) => {
    new Set(text.match(HIGHLIGHT_REGEX))
      .forEach(tag => acc.push(extractHighlightText(tag)))

    return acc
  }, [])

  return Array.from(new Set(words))
}
