import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { sortItemsByTitle, filterTopicsByField } from 'tw-oi-core/utils/data'
import { trackExploreSceneClick, trackExploreTopicClick } from 'tw-oi-core/services/analytics'
import { isEmpty } from 'lodash'

import { connect } from 'react-redux'

import ScreenHead, { SCREEN_HEAD_VARIANT } from '../components/ScreenHead'
import ErrorMessage from '../components/ErrorMessage'

import { ROUTE, MESSAGE } from '../config'

import '../styles/Explore.scss'
import VisualSearch from '../components/VisualSearch'
import TopicsList from '../components/TopicsList'
import Loader from "../components/Loader"
import ScreenContainer from "../components/ScreenContainer"
import Media from "../components/Media"

class Explore extends Component {

  static propTypes = {
    imageMaps: PropTypes.array,
    imageMapBlobs: PropTypes.object,
    fetchingImageMaps: PropTypes.bool.isRequired,
    topics: ImmutablePropTypes.list,
    currentBrand: PropTypes.string.isRequired,
    currentModel: PropTypes.string.isRequired,
    currentYear: PropTypes.string.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        imageMapType: PropTypes.string,
        hotSpotIndex: PropTypes.string,
      }),
      url: PropTypes.string.isRequired
    }).isRequired,
    userMedia: PropTypes.shape({
      isDesktop: PropTypes.bool,
      isLandscape: PropTypes.bool
    }).isRequired,
    history: PropTypes.object.isRequired,
    scrollTop: PropTypes.number,
    scrollUrl: PropTypes.string,
    baseRoute: PropTypes.string.isRequired,
    navBarComponent: PropTypes.elementType,
  }

  navigateToImageMap(imageMap, hotSpotIndex, replace = false) {
    const { history, baseRoute } = this.props
    const url = `${baseRoute}${ROUTE.EXPLORE}/${imageMap}/${hotSpotIndex}`

    // Track analytics event
    const {activeImageMap} = this.getExploreParams()
    if (activeImageMap !== imageMap) {
      trackExploreSceneClick(imageMap)
    }

    if (replace) {
      history.replace(url)
    } else {
      history.push(url)
    }
  }

  /**
   * Extracts the parameters from the props (routing)
   *
   * @returns {{activeImageMap: string, activeHotSpotIndex: number}}
   */
  getExploreParams() {
    const {imageMapType: activeImageMap, hotSpotIndex} = this.props.match.params
    const activeHotSpotIndex = Number.parseInt(hotSpotIndex, 10)
    return {activeImageMap, activeHotSpotIndex}
  }

  /**
   * Triggers search analytics event when search results are clicked
   *
   * @param {Immutable.Map} topic
   */
  onTopicClick(topic) {
    trackExploreTopicClick(topic.get('title'))
  }

  renderContent = () => {
    const { topics, imageMaps, imageMapBlobs, fetchingImageMaps, match, userMedia } = this.props

    // loading is in progress
    if (fetchingImageMaps) {
      return <Loader className="inverse" />
    }

    // loading finished and no data available
    if (isEmpty(imageMapBlobs)) {
      return <Loader type="status" className="inverse">No data available</Loader>
    }

    // TODO: skip images preload and use direct image map URL (direct URLs to be requested from PubHub/CD team)
    const imageMapsItems = imageMaps.map(imageMap => {
      return {...imageMap, url: imageMapBlobs[imageMap.image.url]}
    })

    const {activeImageMap, activeHotSpotIndex} = this.getExploreParams()

    // prepare data for ImageMap
    const currentItem = imageMaps.find((item) => {
      return item.title === activeImageMap
    })

    // hot spot or image map was not found
    if (!currentItem || !currentItem.hotspots || !currentItem.hotspots[activeHotSpotIndex]) {
      return <div className="screen-content">
        <ErrorMessage className="inverse" title={MESSAGE.ERROR_NOT_FOUND} retryAction={ROUTE.INDEX} retryTitle="Visit Home Page" message={MESSAGE.ERROR_NOT_FOUND_MESSAGE}/>
      </div>
    }

    // prepare data for topics list
    const targets = currentItem.hotspots[activeHotSpotIndex].targets
    const targetTopics = sortItemsByTitle(filterTopicsByField(topics, targets, 'resourceKey'))

    return <div className="screen-content">
      <ScreenContainer currentUrl={this.props.baseRoute}>
        <VisualSearch
          imageMaps={imageMapsItems}
          activeImageMap={activeImageMap}
          activeHotSpotIndex={activeHotSpotIndex}
          onChange={this.navigateToImageMap.bind(this)}
          isDesktop={userMedia.isDesktop}
        />
        <div className="topics">
          <Media type="desktop"><h2>Topics to Explore</h2></Media>
          <TopicsList
            topics={targetTopics}
            baseRoute={match.url}
            onClick={(topic) => this.onTopicClick(topic)}
          />
        </div>
      </ScreenContainer>
    </div>
  }

  render() {
    const { baseRoute, navBarComponent } = this.props

    return <div className="Explore">
      <ScreenHead
        title='Manuals'
        baseRoute={baseRoute}
        withSearch
        variant={SCREEN_HEAD_VARIANT.SEARCH}
      />
      {navBarComponent}
      {this.renderContent()}
    </div>
  }
}

function mapStateToProps({ contents, vehicle, user }) {
  const { topics, imageMaps, imageMapBlobs, fetchingImageMaps} = contents
  const { currentYear, currentModel, currentBrand } = vehicle
  const { media:userMedia } = user


  return { topics, imageMaps, currentYear, currentModel, currentBrand, imageMapBlobs, fetchingImageMaps, userMedia }
}


export default connect(mapStateToProps)(Explore)
