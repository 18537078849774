import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { path, pathOr } from 'lodash/fp'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import * as SearchPagesActions from 'tw-oi-core/actions/SearchPagesActions'

import { parseQuery } from './utils'

const withSearchResultsHOC = (Component) => {
  class WithSearchResultsHOC extends PureComponent {
    static propTypes = {
      getSearchPages: PropTypes.func,
    }

    static getDerivedStateFromProps = (props) => {
      const {
        history: { location: { search } },
        match: { params: { resourceKey } },
        publications,
        searchPagesByPublication,
      } = props

      const { q: searchQuery } = parseQuery(search)
      const publication = publications
        .find(pub => pub.get('resourceKey') === resourceKey)
      const publicationId = publication.get('id')

      return {
        searchQuery,
        hasSearchQuery: !!searchQuery,
        isFetching: path([publicationId, 'isFetching'], searchPagesByPublication),
        searchResults: pathOr([], [publicationId, 'data', 'items'], searchPagesByPublication),
        resourceKey,
        publication
      }
    }

    componentDidMount() {
      const { searchQuery, hasSearchQuery, publication } = this.state

      if (hasSearchQuery) {
        this.props.getSearchPages({
          publicationId: publication.get('id'),
          searchQuery
        })
      }
    }

    render() {
      return (
        <Component
          {...this.props}
          {...this.state}
        />
      )
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithSearchResultsHOC)
}

const mapStateToProps = state => ({
  publications: state.contents.publications,
  searchPagesByPublication: state.contents.searchPagesByPublication
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(SearchPagesActions, dispatch)

export default withSearchResultsHOC
