import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getConfig } from 'tw-oi-core'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Immutable from 'immutable'
import Icon from './Icon'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { ROUTE, UI } from '../config'

class BreadCrumb extends Component {

  static propTypes = {
    foldersIndex: ImmutablePropTypes.map.isRequired,
    activeFolder: PropTypes.object.isRequired,
    baseRoute: PropTypes.string.isRequired,
    inactive: PropTypes.bool
  }

  static defaultProps = {
    inactive: false
  }

  renderFoldersPath = (activeFolder) => {
    const { BROWSE_ROOT_KEY } = getConfig()
    const { foldersIndex, baseRoute } = this.props
    const resourceKey = activeFolder.get('resourceKey')
    let foldersPath
    const parentFolder = resourceKey ? foldersIndex.find(folder => {
      return folder.get("contents").keySeq().contains(resourceKey)
    }) : null
    if (parentFolder) {
      foldersPath = this.renderFoldersPath(parentFolder)
    } else {
      foldersPath = new Immutable.List()
    }
    return foldersPath.push(
      <li key={resourceKey || BROWSE_ROOT_KEY}>
        <Link to={`${baseRoute}${ROUTE.BROWSE}${ROUTE.FOLDER}/${resourceKey ? resourceKey : ""}`}>
          {activeFolder.get('title') || UI.BROWSE_TITLE}
        </Link>
        <Icon type="arrow-left"/>
      </li>)
  }

  render() {
    const { activeFolder, inactive } = this.props


    return (
      <ul className={classNames("topics-breadcrumb", inactive && "inactive")}>
        {this.renderFoldersPath(activeFolder)}
      </ul>
    )
  }
}

export default BreadCrumb
