import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { ROUTE, MESSAGE } from '../config'
import Loader from "./Loader"
import Icon from "./Icon"
import { getModelUrl, getModelGroups, getModelsOfGroup } from 'tw-oi-core/utils/vehicle'

import classNames from 'classnames'

import '../styles/VehicleList.scss'

export default class VehicleList extends Component {

  static propTypes = {
    models: PropTypes.array,
    currentYear: PropTypes.string.isRequired,
    currentGroup: PropTypes.string,
    currentModel: PropTypes.string,
    familyModel: PropTypes.string,
    setVehicleModel: PropTypes.func,
    currentYearVehicles: PropTypes.array,
  }

  render() {
    const { currentYear, currentGroup, currentModel, models, familyModel, currentYearVehicles } = this.props

    if (!models) {
      return <div className="VehicleList">
        <Loader className="inverse"/>
      </div>
    }

    if (!models.length) {
      return <div className="VehicleList">
        <Loader type="status" className="inverse">{MESSAGE.EMPTY_VEHICLES}</Loader>
      </div>
    }

    let groups = getModelGroups(currentYearVehicles)
    let groupElements = []
    for (let i = 0; i < groups.length; i++) {
      let modelsOfGroup = getModelsOfGroup(currentYearVehicles, groups[i])
      let modelElements = []
      for (let j = 0; j < modelsOfGroup.length; j++) {
        modelElements.push(
          <Link to={`${ROUTE.GUIDE}${getModelUrl(currentYear, modelsOfGroup[j])}${ROUTE.EXPLORE}`} key={j}
            className={classNames('model', modelsOfGroup[i] === currentModel && 'current')}
            onClick={() => this.props.setVehicleModel && this.props.setVehicleModel(modelsOfGroup[j])}>
            <div className="name">{modelsOfGroup[j]}</div>
          </Link>
        )
      }

      groupElements.push(<div key={i}
        className={classNames('group', groups[i] === currentGroup && 'current')}>
        {!familyModel && <h2>{groups[i]}</h2>}
        <div className="list">
          {modelElements}
        </div>
      </div>)

    }

    return <div className="VehicleList">
      {familyModel &&
      <ul className="breadcrumb">
        <li>
          <Link to={`${ROUTE.VEHICLES}/${currentYear}`}>All Models</Link>
          <Icon type="arrow-left"/>
        </li>
        <li>
          <div>{familyModel}</div>
          <Icon type="arrow-left"/>
        </li>
      </ul>}
      <div className="list">
        {groupElements}
      </div>
    </div>
  }
}
