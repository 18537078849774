/* global process */

export const ROUTE = {
  INDEX: '/',
  VEHICLES: '/vehicles',
  ABOUT: '/about',
  GUIDE: '/guide',
  HOME: '/home',
  EXPLORE: '/explore',
  SEARCH: '/search',
  TOPPICKS: '/top-picks',
  INDICATORS: '/indicators',
  BROWSE: '/browse',
  TOPIC: '/topic',
  FOLDER: '/folder',
  FAVORITES: '/favorites',
  BRANDS: '/brands',
  PROMO: '/promo',
  PDF_PUBLICATION_MATCHES: '/pdf-publication/:resourceKey/matches',
  PDF_PUBLICATION: '/pdf-publication/:resourceKey',
  PUBLICATION_GROUP: '/publication-group/:publicationId',
  WARNING_LIGHTS: '/warning-lights',
}

export const BRAND_NISSAN = 'Nissan'
export const BRAND_INFINITI = 'Infiniti'

export const APP_VERSION = process.env.REACT_APP_VERSION || '2.1.x-dev'

export const GA_APPLICATION_TYPE_MYNISSAN = 'MyNissan'

export const VEHICLE_GROUP_CARS = 'Cars'
export const VEHICLE_GROUP_SPORTS_CARS = 'Sports Cars'
export const VEHICLE_GROUP_SUV = 'Crossovers & SUVs'
export const VEHICLE_GROUP_TRUCKS = 'Trucks'
export const VEHICLE_GROUP_VANS = 'Vans'

export const HEROSHOT_DEFAULT_IMAGE_SIZE = 'medium'

export const VEHICLE_FAMILIES = {
  2014: {
    pathfinder: { name: 'Pathfinder', models: ['Pathfinder', 'Pathfinder Hybrid'] },
    qx60: { name: 'QX60', models: ['QX60', 'QX60 Hybrid'] },
    q50: { name: 'Q50', models: ['Q50', 'Q50 Hybrid'] },
    q70: { name: 'Q70', models: ['Q70', 'Q70 Hybrid'] },
  },
  2015: {
    'q50-sedan': { name: 'Q50', models: ['Q50 Sedan', 'Q50 Hybrid'] },
    q70: { name: 'Q70', models: ['Q70', 'Q70 Hybrid'] },
    qx60: { name: 'QX60', models: ['QX60', 'QX60 Hybrid'] },
  },
  2016: {
    murano: { name: 'Murano', models: ['Murano', 'Murano Hybrid'] },
    'q50-sedan': { name: 'Q50', models: ['Q50 Sedan', 'Q50 Hybrid'] },
    q70: { name: 'Q70', models: ['Q70', 'Q70 Hybrid'] },
    qx60: { name: 'QX60', models: ['QX60', 'QX60 Hybrid'] },
  },
  2017: {
    rogue: { name: 'Rogue', models: ['Rogue', 'Rogue HEV'] },
    'q50-sedan': { name: 'Q50', models: ['Q50 Sedan', 'Q50 HEV'] },
    q70: { name: 'Q70', models: ['Q70', 'Q70 HEV'] },
  },
  2018: {
    rogue: { name: 'Rogue', models: ['Rogue', 'Rogue Hybrid'] },
    'q50-sedan': { name: 'Q50', models: ['Q50 Sedan', 'Q50 Hybrid'] },
    'q70-sedan': { name: 'Q70', models: ['Q70 Sedan', 'Q70 Hybrid'] },
  },
  2019: {
    rogue: { name: 'Rogue', models: ['Rogue', 'Rogue Hybrid'] },
  },
}

// WARNING! Do not use this value directly, only with initCore/getConfig from tw-oi-core
export const CORE = {
  FEATURE_ENABLED_FAVORITES: false,
  FEATURE_ENABLED_DRAFT_CONTENT_PREVIEW: false,

  BASE_URL: process.env.REACT_APP_BASE_URL,
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,

  DEFAULT_BRAND: process.env.REACT_APP_DEFAULT_BRAND || BRAND_NISSAN,

  BROWSE_FOLDERS_KEYS: ['id', 'type', 'title', 'resourceKey', 'contents', 'alias', 'video'],

  IMAGE_MAPS_ORDER: [
    'Exterior',
    'WideCabin',
    'DriverSeat',
    'FrontSeat',
    'RearSeat',
    'Rear',
    'TruckBed',
    'Hatch',
    'Cargo',
    'SteeringWheel',
    'IP', // 'InstrumentPanel'
  ],

  // Analytics setup >

  FULL_STORY_ORG_CODE: '8W224',
  FULL_STORY_DEBUG: false,

  GA_CODES_BY_ENV: {
    Nissan: [
      { trackingId: 'G-2DK63MFE74' }, // google analytics 4 UAT
      { trackingId: 'UA-72555763-10' }, // universal analytics UAT
    ],
    Infiniti: [
      { trackingId: 'G-4WRY1PBP56' }, // google analytics 4 UAT
      { trackingId: 'UA-72555763-11' }, // universal analytics UAT
    ],
    'nissanquickguide.com': [
      { trackingId: 'G-GLJSNK0JNH' }, // google analytics 4
      { trackingId: 'UA-72555763-12' }, // Nissan Quick Guide 2.0 universal
    ],
    'infinitiquickguide.com': [
      { trackingId: 'G-D9RY64MM2X' }, // google analytics 4
      { trackingId: 'UA-72555763-13' }, // Infiniti Quick Guide 2.0 universal
    ],
    'mynissan.quick.guide': [
      { trackingId: 'G-GLJSNK0JNH' }, // google analytics 4
      { trackingId: 'UA-72555763-12' }, // Nissan Quick Guide 2.0 universal
    ],
  },

  GOOGLE_ANALYTICS_DEBUG: false,

  LOGENTRIES_TOKEN: '0662ff16-cfe9-47d3-bc64-d0f9014e67b6',

  // < Analytics setup

  ERROR_LOG_ENDPOINT: '/logs',

  DEFAULT_GROUP: 'Cars',

  TEMPORARY_GROUPS: {
    // Nissan models
    'Versa Note': VEHICLE_GROUP_CARS,
    Altima: VEHICLE_GROUP_CARS,
    'Altima Coupe': VEHICLE_GROUP_CARS,
    'Altima Sedan': VEHICLE_GROUP_CARS,
    Kicks: VEHICLE_GROUP_SUV,
    'NV Passenger Van': VEHICLE_GROUP_VANS,
    'NV Cargo Van': VEHICLE_GROUP_VANS,
    'Versa Sedan': VEHICLE_GROUP_CARS,
    Sentra: VEHICLE_GROUP_CARS,
    Rogue: VEHICLE_GROUP_SUV,
    'Rogue Sport': VEHICLE_GROUP_SUV,
    'Rogue HEV': VEHICLE_GROUP_SUV,
    'Rogue Hybrid': VEHICLE_GROUP_SUV,
    Pathfinder: VEHICLE_GROUP_SUV,
    'Pathfinder Hybrid': VEHICLE_GROUP_SUV,
    'Pathfinder HEV': VEHICLE_GROUP_SUV,
    Cube: VEHICLE_GROUP_CARS,
    '370Z': VEHICLE_GROUP_SPORTS_CARS,
    NV200: VEHICLE_GROUP_VANS,
    Murano: VEHICLE_GROUP_SUV,
    'Murano Hybrid': VEHICLE_GROUP_SUV,
    Armada: VEHICLE_GROUP_SUV,
    Frontier: VEHICLE_GROUP_TRUCKS,
    LEAF: VEHICLE_GROUP_CARS,
    'LEAF®': VEHICLE_GROUP_CARS,
    'GT-R': VEHICLE_GROUP_SPORTS_CARS,
    GTR: VEHICLE_GROUP_SPORTS_CARS,
    Maxima: VEHICLE_GROUP_CARS,
    Juke: VEHICLE_GROUP_SUV,
    Quest: VEHICLE_GROUP_VANS,
    NV2000: VEHICLE_GROUP_VANS,
    'LEAF(R)': VEHICLE_GROUP_CARS,
    Titan: VEHICLE_GROUP_TRUCKS,
    TITAN: VEHICLE_GROUP_TRUCKS,
    'TITAN Diesel': VEHICLE_GROUP_TRUCKS,
    Xterra: VEHICLE_GROUP_SUV,
    cube: VEHICLE_GROUP_VANS,
    'NV200 Compact Cargo': VEHICLE_GROUP_VANS,
    'NV1500 NV2500HD NV3500HD': VEHICLE_GROUP_VANS,
    'NV1500 NV2500HD NV3500': VEHICLE_GROUP_VANS,
    'NV1500/2500HD/3500HD': VEHICLE_GROUP_VANS,
    'NV1500/NV2500HD/NV3500': VEHICLE_GROUP_VANS,
    'Rogue Select': VEHICLE_GROUP_SUV,
    Versa: VEHICLE_GROUP_CARS,
    'Versa Hatchback': VEHICLE_GROUP_CARS,

    // Infiniti models
    Q60: VEHICLE_GROUP_SPORTS_CARS,
    QX80: VEHICLE_GROUP_SUV,
    Q70: VEHICLE_GROUP_CARS,
    Q50: VEHICLE_GROUP_CARS,
    QX30: VEHICLE_GROUP_SUV,
    QX50: VEHICLE_GROUP_SUV,
    QX60: VEHICLE_GROUP_SUV,
    'QX60 Hybrid': VEHICLE_GROUP_SUV,
    EX: VEHICLE_GROUP_SUV,
    FX: VEHICLE_GROUP_SUV,
    G: VEHICLE_GROUP_SPORTS_CARS,
    'G37 Sedan': VEHICLE_GROUP_CARS,
    'G37 Coupe/Convertible': VEHICLE_GROUP_CARS,
    JX: VEHICLE_GROUP_SUV,
    M: VEHICLE_GROUP_SPORTS_CARS,
    QX: VEHICLE_GROUP_SPORTS_CARS,
    'Q60 Coupe or Convertible': VEHICLE_GROUP_SPORTS_CARS,
    Q40: VEHICLE_GROUP_CARS,
    QX70: VEHICLE_GROUP_SUV,
    'Q50 Sedan': VEHICLE_GROUP_CARS,
    'Q50 HEV': VEHICLE_GROUP_CARS,
    'Q50 Hybrid': VEHICLE_GROUP_CARS,
    'Q70 Sedan': VEHICLE_GROUP_CARS,
    'Q70 Hybrid': VEHICLE_GROUP_CARS,
    'Q70 HEV': VEHICLE_GROUP_CARS,
    'M Conventional': VEHICLE_GROUP_CARS,
    'M Hybrid': VEHICLE_GROUP_CARS,

    // Draft preview models
    'Datsun Roadster': VEHICLE_GROUP_SPORTS_CARS,
    'Datsun Roadster 2': VEHICLE_GROUP_SPORTS_CARS,
    'Datsun Roadster 3': VEHICLE_GROUP_SPORTS_CARS,
    'Datsun Roadster 4': VEHICLE_GROUP_SPORTS_CARS,
  },

  VEHICLE_MODELTYPE_TO_GROUPS_MAP: {
    suv: VEHICLE_GROUP_SUV,
    car: VEHICLE_GROUP_CARS,
    truck: VEHICLE_GROUP_TRUCKS,
    sportsCar: VEHICLE_GROUP_SPORTS_CARS,
    van: VEHICLE_GROUP_VANS,
  },

  COMING_SOON_CONTENT: [
    { id: 'comingsoon4', make: 'Nissan', model: 'Kicks', year: '2019' },
    { id: 'comingsoon6', make: 'Nissan', model: 'Altima', year: '2019' },
    { id: 'comingsoon7', make: 'Nissan', model: 'Maxima', year: '2019' },
    { id: 'comingsoon9', make: 'Nissan', model: 'GT-R', year: '2019' },
    { id: 'comingsoon11', make: 'Nissan', model: 'Pathfinder', year: '2019' },
    { id: 'comingsoon12', make: 'Nissan', model: 'Murano', year: '2019' },
    { id: 'comingsoon15', make: 'Nissan', model: 'Rogue Sport', year: '2019' },
    { id: 'comingsoon18', make: 'Nissan', model: 'NV Cargo Van', year: '2019' },
    { id: 'comingsoon19', make: 'Nissan', model: 'NV Passenger Van', year: '2019' },
    { id: 'comingsoon22', make: 'Infiniti', model: 'Q60', year: '2019' },
    { id: 'comingsoon24', make: 'Infiniti', model: 'QX30', year: '2019' },
  ],
}

export const UI = {
  SEARCH_BOX_DELAY: 500,
  SEARCH_MIN_CHARACTERS: 3,

  ANIMATION_DELAY: 300,
  FRAME_DELAY: 300,
  REM_SIZE: 20,
  MOBILE_WIDTH: 770,
  EXPLORE_ZOOM: 2,

  NO_FOLDER_TITLE: 'Filter by category',
  ALL_FOLDERS_TITLE: 'All Categories',
  BROWSE_TITLE: 'Manuals',

  // These values are hardcoded here for a reason
  // SEE: https://tweddle.atlassian.net/browse/TWSDEL-1058
  EXPLORE_IMAGE_WIDTH: 800,
  EXPLORE_IMAGE_HEIGHT: 490,
  EXPLORE_HOTSPOT_SIZE: 40,
  EXPLORE_ZOOM_ANIMATION: { stiffness: 250, damping: 25 },

  GROUP_MARGIN: 5,

  SCROLL_TYPE_MANUAL: 'manual',
  SCROLL_TYPE_USER: 'user',

  MODAL_ID_TERMS: 'terms',

  URL_APPSTORE: {
    [BRAND_NISSAN]: 'https://itunes.apple.com/us/app/nissan-quick-guide/id943513879?mt=8',
    [BRAND_INFINITI]: 'https://itunes.apple.com/us/app/infiniti-quick-guide/id990231772?mt=8',
  },
  URL_PLAYMARKET: {
    [BRAND_NISSAN]: 'https://play.google.com/store/apps/details?id=com.tweddle.nissan&hl=en',
    [BRAND_INFINITI]: 'https://play.google.com/store/apps/details?id=com.tweddle.infiniti&hl=en',
  },
}

export const MESSAGE = {
  CONFIRM_REMOVE_FAVORITE: 'Are you sure you want remove this topic from Favorites?',
  ROTATE_DEVICE_ORIENTATION: 'Please rotate your device to portrait orientation for a better experience with the app',

  EMPTY_FAVORITES: 'No topics added yet',
  EMPTY_RECENT: 'No topics viewed yet',
  EMPTY_TOPICS: 'No topics found',
  EMPTY_VEHICLES: 'No vehicles found',
  COMING_SOON: 'Coming soon',

  ERROR_GLOBAL: 'Something went wrong',
  ERROR_RETRY: 'Please retry in a while',
  ERROR_VEHICLE_NOT_FOUND: 'Vehicle not found',
  ERROR_VEHICLE_RETRY: 'Try to select another vehicle',

  ERROR_NOT_FOUND: 'Page Not found',
  ERROR_NOT_FOUND_MESSAGE: "The page you've requested was not found",
}
