import qs from 'qs'
import { slice } from 'lodash'
import { compose, defaultTo, join } from 'lodash/fp'

export const parseQuery = compose(
  qs.parse,
  join(''),
  query => slice(query, 1),
  defaultTo('')
)
